import * as React from 'react'
import Navbar from '../Setup/Navbar'
import { Container, Grid2, Typography } from '@mui/material'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import AutoCompleteSelf from '../../BuiltInComponent/AutoComplete'
import { useDispatch, useSelector } from 'react-redux'
import { getSectionNames, getmrgItemName, getSectionData, getMergeItemData } from '../../features/Clients/production/productionSlice'
import { useNavigate } from 'react-router-dom';
import RowRadioButtonsGroup from '../../BuiltInComponent/RadioButton';
import Loading from '../../BuiltInComponent/Loading'

export default function SectionWiseProduct() {

    const isMobile = useMediaQuery('(max-width:600px)')

    const dispatch = useDispatch()

    const { sectionNames, mrgItemName, sectionData, isLoading } = useSelector(state => state.pr)

    const [selectedValue, setSelectedValue] = React.useState('quantity');
    const [sectionCode, setSectionCode] = React.useState();
    const [mrgItemCode, setMrgItemCode] = React.useState();


    React.useEffect(() => {
        dispatch(getSectionNames())
        dispatch(getmrgItemName())
    }, [dispatch])


    const handleChangeRadioButton = (rbValue) => {
        setSelectedValue(rbValue);
        console.log('Selected Value with Main Window :', rbValue);
        if (sectionCode === '') {
            dispatch(getMergeItemData({ mrgItemCode: mrgItemCode, order: rbValue }))
        } else {
            dispatch(getSectionData({ sectionCode: sectionCode, order: rbValue }))
        }
    };

    const onChange = (data) => {
        const keys = Object.keys(data)
        setSectionCode(data[keys])
        setMrgItemCode('')
        if (keys[0] === 'sectionCode') {
            dispatch(getSectionData({ sectionCode: data[keys], order: selectedValue }))
        }
    }

    const onChangeMergeItem = (data) => {
        const keys = Object.keys(data)
        console.log(keys)
        setSectionCode('')
        setMrgItemCode(data[keys])
        if (keys[0] === 'mrgItemCode') {
            dispatch(getMergeItemData({ mrgItemCode: data[keys], order: selectedValue }))
        }
    }

    return (
        <>
            <Navbar />
            <Container>
                <Grid2 container spacing={2}>
                    <Grid2 size={12}><Typography
                        sx={{
                            textAlign: 'center', fontSize: 35, fontWeight: 'bolder', backgroundColor: 'black',
                            color: 'white', borderRadius: 3, textShadow: 'grey 5px 5px 5px'
                        }}
                    >Production Report</Typography></Grid2>
                    <Grid2 size={isMobile ? 12 : 4} display='flex' justifyContent='center'>
                        <RowRadioButtonsGroup
                            handleChangeRadioButton={handleChangeRadioButton}
                            selectedValue={selectedValue} />
                    </Grid2>
                    <Grid2 size={isMobile ? 12 : 4}>
                        <AutoCompleteSelf
                            listData={sectionNames && sectionNames}
                            onChange={onChange}
                            label='Select Section Name'
                        />
                    </Grid2>
                    <Grid2 size={isMobile ? 12 : 4}>
                        <AutoCompleteSelf
                            listData={mrgItemName && mrgItemName}
                            onChange={onChangeMergeItem}
                            label='Select Item Name'
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        {isLoading ?
                            <Loading /> :
                            <StickyHeadTable
                                tableColumns={sectionData.columns && sectionData.columns}
                                tableData={sectionData.rows && sectionData.rows}
                                perPage={50} />
                        }
                        {/* <DataGridSelf rows={sectionData && sectionData.rows} columns={sectionData && sectionData.columns} /> */}
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}


function StickyHeadTable(props) {

    const navigate = useNavigate()
    const theme = useTheme()

    const perPage = props.perPage
    const tableColumns = props.tableColumns
    const rows = props.tableData

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 5);
    // const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle the filter input change
    // const handleFilterChange = (event) => {
    //     const filterValue = event.target.value.toLowerCase();
    //     // setFilter(filterValue);

    //     // Filter rows based on filter input
    //     const filteredData = rows.filter(row => {
    //         return tableColumns.some(col => {
    //             const cellValue = row[col.fieldName]?.toString().toLowerCase();
    //             return cellValue && cellValue.includes(filterValue);
    //         });
    //     });

    //     setFilteredRows(filteredData);
    // };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            {/* <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size='small'
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2> */}
            <TableContainer >
                <Table stickyHeader aria-label="sticky table" size='small'>
                    <TableHead>
                        <TableRow>
                            {tableColumns && tableColumns.slice(1, tableColumns.length).map((columns, index) => (
                                tableColumns[index + 1].status === 'en' ?
                                    <>
                                        <TableCell
                                            key={index}
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: 'white',
                                                textAlign: `${tableColumns[index + 1].textAlign}`,
                                                fontWeight: 'bolder',
                                                width: `${columns.width}`,
                                                textShadow: 'black 2px 2px 4px'
                                            }}
                                        >
                                            {columns.cName}
                                        </TableCell>
                                    </> : <></>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows && filteredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                return (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        style={{ width: '70%', }}
                                    >
                                        {tableColumns.slice(1, tableColumns.length).map((col, index) => (
                                            <TableCell
                                                key={index}
                                                style={{
                                                    textAlign: `${tableColumns[index + 1].dType === 'number' ?
                                                        index === 0 ? 'center' : 'right' : 'left'}`,
                                                    fontWeight: `${rowIndex === filteredRows.length - 1 ? 'bolder' : ''}`,
                                                    fontSize: rowIndex === rows.length - 1 ? 15 : 13,
                                                    width: tableColumns[index + 1].width,
                                                    cursor: index === 0 ? 'pointer' : '',
                                                }}
                                                component="th"
                                                scope="row"
                                                sx={{ border: 'ridge 1px' }}
                                                onClick={() => {
                                                    const itemCode = row.itemCode
                                                    navigate('/Stock/BuyerWiseStock', { state: { itemCode } })
                                                }}
                                            >
                                                {tableColumns[index + 1].dType === 'number'
                                                    ? `${row[tableColumns[index + 1].fieldName] ? Math.round(row[tableColumns[index + 1].fieldName]).toLocaleString("en-US") : '0'}`
                                                    : row[tableColumns[index + 1].fieldName] ? row[tableColumns[index + 1].fieldName] : '0'}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows && filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
