import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Navbar from './Navbar'
import GetCategoryData from '../reports/GetCategoryData'


export default function Dashbaord() {

    const navigate = useNavigate()

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (!user) {
            navigate('SignIn')
        }
    }, [user, navigate])


    return (
        <>
            <Navbar />
            <Container sx={{ mt: 2 }} >
                <GetCategoryData />
            </Container >
        </>
    )
}
