import React, { useEffect } from 'react'
import { Container, Grid2, Typography, Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryData } from '../../features/reports/fetchedDataSlice'
// import BreadCrumb from '../../BuiltInComponent/BreadCrumb'
import Loading from '../../BuiltInComponent/Loading'
import dayjs from 'dayjs'

export default function GetCategoryData() {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const navigate = useNavigate()

    const { gcd, isLoading } = useSelector(state => state.fdr)

    useEffect(() => {
        dispatch(getCategoryData())
    }, [dispatch])

    const gridStyle = { backgroundColor: 'green', padding: 1, borderRadius: 2, boxShadow: 'black 2px 2px 5px', cursor: 'pointer' }
    const categoryStyle = { textAlign: 'center', fontSize: 18, fontWeight: 'bolder', color: 'white', textShadow: 'black 2px 2px 5px' }
    // const arrayBreadCrumbs = [
    //     { value: 'Categories', href: '/' },
    // ]
    return (
        <>
            <Container sx={{ mt: 2 }} >
                {/* <BreadCrumb arrayBreadCrumbs={arrayBreadCrumbs} /> */}
                {isLoading
                    ?
                    <Loading />
                    :
                    <Grid2 container spacing={2}>
                        {gcd.map((item) => (
                            <>
                                <Grid2 size={isMobile ? 12 : 3} sx={gridStyle} onClick={() => {
                                    const category = item.category
                                    navigate('/Reports/GetBrandData', { state: { category } })

                                }}>
                                    <Typography sx={categoryStyle}>{item.category}</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white', textShadow: 'black 3px 3px 3px' }}>
                                        <Typography sx={{ fontWeight: 'bolder', fontSize: 16 }}>
                                            Count : {item.totalRecords}
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bolder', fontSize: 16 }}>
                                            Avg Pr : $ {Math.round(item.avgPrice).toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white', textShadow: 'black 3px 3px 3px' }}>
                                        <Typography sx={{ fontWeight: 'bolder', fontSize: 16 }}>
                                            {dayjs(item.lastFetchedDate).format('DD-MM-YYYY')}
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bolder', fontSize: 20 }}>
                                            $ {Math.round(item.TotalAmount).toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Grid2>
                            </>
                        ))}
                    </Grid2>
                }
            </Container >
        </>
    )

}