import * as React from 'react'
import Navbar from '../Setup/Navbar'
import Loading from '../../BuiltInComponent/Loading'
import { useLocation } from 'react-router-dom'
import { Container, Grid2, Typography, Link, Box, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getItemDetailsData } from '../../features/reports/fetchedDataSlice'

export default function ItemDetail() {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const dispatch = useDispatch()
    const location = useLocation()

    const { idd, isLoading } = useSelector(state => state.fdr)

    const { category, Brand } = location.state || {}



    // const arrayBreadCrumbs = [
    //     { value: 'Categories', href: '/' },
    //     { value: 'Brands', href: '/Reports/GetBrandData' },
    //     { value: 'Details', href: '/Reports/ItemDetails' },
    // ]

    React.useEffect(() => {
        dispatch(getItemDetailsData({ category: category, brand: Brand }))
    }, [dispatch, category, Brand])

    const mainGrid = { backgroundColor: '#fff', p: 1, borderRadius: 4, boxShadow: 'black 2px 2px 4px', cursor: 'pointer', border: 'black solid 2px' }
    const itemHeading = { fontWeight: 'bolder', fontSize: 16 }
    const getItemDetails = (itemURL) => {
        window.open(itemURL, '_blank', 'noopener,noreferrer')
    }
    return (
        <>
            <Navbar />
            <Container sx={{ mt: 2 }}>
                {isLoading ?
                    <Loading /> :
                    <Grid2 container spacing={2} >
                        {idd && idd.length && idd.map((item, index) => (
                            <>
                                <Grid2 size={isMobile ? 12 : 4} sx={mainGrid} onClick={() => { getItemDetails(item.itemUrl) }} >
                                    <Box sx={{ minHeight: 120 }}>
                                        <Typography sx={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: 30, color: 'blue', fontWeight: 'bolder', textShadow: 'red 2px 2px 5px ' }}>Price : {item.price}</span>
                                        </Typography>

                                        <Typography>
                                            <span style={itemHeading}>Title :</span>
                                            <br />
                                            <span style={{ fontSize: 13 }}>{item.title}</span>
                                        </Typography>
                                        <Typography>
                                            <span style={itemHeading}>Shipping : </span>
                                            {item.shpping}
                                        </Typography>
                                        {/* <Typography>{{ item.details }}</Typography> */}
                                    </Box>
                                    <DetailsData details={JSON.parse(item.details)} />
                                </Grid2>
                            </>
                        ))}

                    </Grid2>
                }
                {/* <BreadCrumb arrayBreadCrumbs={arrayBreadCrumbs} /> */}

            </Container>
        </>
    )
}

function TruncatedText({ text, maxLength = 50 }) {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const shouldTruncate = text.length > maxLength;
    const displayedText = isExpanded || !shouldTruncate ? text : `${text.slice(0, maxLength)}...`;

    return (
        <>
            <Typography fontSize={10}>
                {displayedText}
            </Typography>
            {shouldTruncate && (
                <Link component="button" onClick={toggleExpand} sx={{ fontSize: 10 }}>
                    {isExpanded ? "Read Less" : "Read More"}
                </Link>
            )}
        </>
    );
}


function DetailsData(props) {

    const details = props.details

    return (
        <>
            <Grid2 container spacing={1} sx={{ border: 'black solid 1px', minHeight: 400, borderRadius: 5, p: 1, boxShadow: 'black 1px 1px 1px ' }}>
                <Grid2 size={12} sx={{ mb: -10 }}>
                    <Typography sx={{ ml: 2 }}>Details : -</Typography>
                </Grid2>
                {Object.entries(details).map(([key, value]) => (
                    <Grid2 size={4}>
                        <Typography sx={{ fontWeight: 'bolder', fontSize: 13 }}>{key}</Typography>
                        <TruncatedText text={value} maxLength={50} />
                    </Grid2>
                ))}
            </Grid2>
        </>
    )
}