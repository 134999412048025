import {
    Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
    TableRow, useTheme, Grid2, TextField
} from '@mui/material'; import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { getItemData } from '../../features/reports/fetchedDataSlice'
import Navbar from '../Setup/Navbar';
import Loading from '../../BuiltInComponent/Loading'
import HeadingText from '../../BuiltInComponent/HeadingTest';
export default function ItemData() {

    const location = useLocation()

    const dispatch = useDispatch()

    const { idata, isLoading } = useSelector(state => state.fdr)

    const { category, Brand, Model } = location.state || {}

    React.useEffect(() => {
        dispatch(getItemData({ category: category, brand: Brand, model: Model }))
    }, [dispatch, category, Brand, Model])


    const columns = [
        { fieldName: 'category', cName: 'Category', status: 'en', dType: 'string', width: 300, textAlign: 'center' },
        { fieldName: 'title', cName: 'Title', status: 'en', dType: 'string', width: 300, textAlign: 'center' },
        { fieldName: 'price', cName: 'Price', status: 'en', dType: 'number', width: 300, textAlign: 'center' },
        { fieldName: 'details.Style', cName: 'Style', status: 'en', dType: 'string', width: 300, textAlign: 'center' },
        { fieldName: 'details.Condition', cName: 'Condition', status: 'en', dType: 'string', width: 1000, textAlign: 'center' },
        { fieldName: 'details.Color', cName: 'Color', status: 'en', dType: 'string', width: 300, textAlign: 'center' },
    ]

    return (
        <>
            <Navbar />
            <Container sx={{ mt: 2 }} >
                <HeadingText text={'Item Data'} />
                {/* <BreadCrumb arrayBreadCrumbs={arrayBreadCrumbs} /> */}
                {isLoading
                    ?
                    <Loading />
                    :
                    <StickyHeadTable
                        tableColumns={columns && columns}
                        tableData={idata && idata}
                        perPage={50}
                        category={category}
                        brand={Brand}
                    />
                }
            </Container>
        </>
    )
}


function StickyHeadTable(props) {
    const navigate = useNavigate();
    const theme = useTheme();

    const perPage = props.perPage;
    const tableColumns = props.tableColumns;
    const rows = props.tableData;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 50);
    const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

    // Utility function to get nested field values

    const getNestedValue = (obj, path, defaultValue = 'not found') => {
        return path
            .split('.')
            .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj) || defaultValue;
    };

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle the filter input change
    const handleFilterChange = (event) => {
        const filterValue = event.target.value.toLowerCase();
        setFilter(filterValue);

        // Filter rows based on filter input
        const filteredData = rows.filter((row) => {
            return tableColumns.some((col) => {
                const cellValue = getNestedValue(row, col.fieldName)?.toString().toLowerCase();
                return cellValue && cellValue.includes(filterValue);
            });
        });

        setFilteredRows(filteredData);
    };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size="small"
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            {tableColumns &&
                                tableColumns.map((columns, index) =>
                                    tableColumns[index].status === 'en' ? (
                                        <TableCell
                                            key={index}
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: 'white',
                                                textAlign: `${tableColumns[index].textAlign}`,
                                                fontWeight: 'bolder',
                                                width: `${columns.width}`,
                                                textShadow: 'black 2px 2px 4px',
                                            }}
                                        >
                                            {columns.cName}
                                        </TableCell>
                                    ) : null
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows &&
                            filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, rowIndex) => {
                                    return (
                                        <TableRow
                                            key={rowIndex}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{ width: '70%' }}
                                        >
                                            {tableColumns.map((col, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{
                                                        textAlign: `${col.dType === 'number'
                                                            ? index === 0
                                                                ? 'center'
                                                                : 'right'
                                                            : col.dType === 'decimal'
                                                                ? 'right'
                                                                : 'left'
                                                            }`,
                                                        fontWeight: `${rowIndex === filteredRows.length - 1 ? 'bolder' : ''
                                                            }`,
                                                        fontSize: rowIndex === rows.length - 1 ? 15 : 13,
                                                        width: col.width,
                                                        cursor: index === 1 ? 'pointer' : '',
                                                    }}
                                                    component="th"
                                                    scope="row"
                                                    sx={{ border: 'ridge 1px' }}
                                                    onClick={() => {
                                                        const Model = row.Model;
                                                        const Brand = props.brand;
                                                        const category = props.category;
                                                        navigate('/Reports/ItemData', { state: { Model, Brand, category } });
                                                    }}
                                                >
                                                    {getNestedValue(row, col.fieldName) || ''}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows && filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
