import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashbaord from "./compnents/Setup/Dashbaord";
import SignIn from "./compnents/CleintComponent/SignIn";
import AddNewUser from "./compnents/Security/AddNewUser";
import AddNewMenu from "./compnents/Security/AddNewMenu";
import SetUserRights from './compnents/Security/SetUserRights'
import SectionWiseProduct from "./compnents/production/SectionWiseProduction";
import BuyerWiseStock from "./compnents/stock/BuyerWiseStock";
import SalesStock from "./compnents/sales/SalesReport"
import GetBrandData from './compnents/reports/GetBrandData'
import ItemDetail from "./compnents/reports/ItemDetails";
import GetModelWiseData from "./compnents/reports/GetModelWiseData";
import ItemData from "./compnents/reports/ItemData";
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f9cff'
    },
    secondary: {
      main: '#ffffff',
    },
    custom: {
      main: "#ffffff"
    }
  },
  typography: {
    fontFamily: [
      'Calibri'
    ].join(','),
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* Security Routes Starts */}
          <Route path="/" element={<Dashbaord />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/AddNewuser" element={<AddNewUser />} />
          <Route path="/AddNewMenu" element={<AddNewMenu />} />
          <Route path="/SetUserRights" element={<SetUserRights />} />
          <Route path="/Production/SectionWiseProduct" element={<SectionWiseProduct />} />
          <Route path="/Stock/BuyerWiseStock" element={<BuyerWiseStock />} />
          <Route path="/Sales/SalesReport" element={<SalesStock />} />
          <Route path="/Reports/GetBrandData" element={<GetBrandData />} />
          <Route path="/Reports/GetModelData" element={<GetModelWiseData />} />
          <Route path="/Reports/ItemData" element={<ItemData />} />
          <Route path="/Reports/ItemDetails" element={<ItemDetail />} />
          {/* Security Routes Ends */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
